@font-face {
  font-family: "Mc Fredy";
  src: url("./assets/fonts/McFredy-Regular.ttf") format("truetype"),
    url("./assets/fonts/McFredy-Outline.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "CustomFont", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
