.container {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 19px;

  border-radius: 30px;
  border: 1px solid #fff;
  background: #000;

  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 24px */

  cursor: pointer;
}
