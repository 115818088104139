.container {
  width: 100vw;
  height: 100vh;
  min-height: 800px;
  overflow: hidden;
  background: url("../../assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.mainLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
}
.mainLogo {
  width: 300px;
  height: 300px;
  background: url("../../assets/images/main.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.sloganContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}
.slogan {
  color: #fff;
  text-align: center;
  font-family: "Mc Fredy";
  font-size: 40px;
  font-style: normal;
  max-width: 1000px;
  line-height: 100%; /* 40px */
}
.btns {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  gap: 20px;
  flex-wrap: wrap;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  // max-width: 800px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: #fff;
}

.swiperSlide {
  display: inline-block;
  width: 300px !important;
}

@media screen and (max-width: 768px) {
  .container {
    // min-height: 1200px;
  }
  .mainLogoContainer {
    margin-top: 30px;
  }

  .footer {
    max-width: 100vw;
    overflow: hidden;
    margin-bottom: 30px;
  }

  .slogan {
    font-size: 30px;
  }

  .swiperSlide {
    width: 200px !important;
  }
}
